import React from 'react';

/**
 * Images/SVG
 */
import Background from '../../images/inner-page.png';
import { CanonLogo } from '../../components/canon-logo/canon-logo';
import Css from '../../images/css.png';
import Html from '../../images/html.png';
import Js from '../../images/js.png';
import ProImg1 from '../../images/pro1.png';
import ProImg2 from '../../images/pro2.png';
import ProImg3 from '../../images/pro3.png';

/**
 * Components
 */
import SEO from '../../components/seo/seo';
import Layout from '../../components/layout/layout';
import Banner from '../../components/default-banner/default-banner';
import Company from 
  '../../components/sub-page-client-company/sub-page-client-company';
// import Testimonial from 
//   '../../components/sub-page-client-testimonial/sub-page-client-testimonial';
import SeeMore from 
  '../../components/sub-page-client-see-more/sub-page-client-see-more';
import Brief from 
  '../../components/sub-page-client-brief/sub-page-client-brief';
import Challenge from 
  '../../components/sub-page-client-challenge/sub-page-client-challenge';


export default () => {

  /**
   * Banner
   */
  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'PRO',
    mainText: 'Canon for professionals'
  };

  /**
   * Company data
   */
  const descriptionData = {
    link: 'https://www.canon-europe.com/pro/',
    logo: <CanonLogo />,
    titleDescription: 
      'Canon PRO – Enhancing product visibility with Strategic Marketing',
    description: 
      'Canon Europe is a subsidiary of Canon Inc. a world-leading innovator and provider of imaging solutions such inkjet printers, digital cameras, digital SLR cameras, & professional printers for business and home users.',
    tech:[
      <img alt="html logo" className="html" src={Html}/>,
      <img alt="css logo" className="css" src={Css}/>,
      <img alt="js logo" className="js" src={Js}/>
    ]
  };

  /**
   * Brief Data
   */
  const briefData = {
    description: 
      'Canon Pro wanted radium agency to create a marketing website that offers maximum exposure on all imaging products to their target audience with a directed approach.',
    images: [
      <img alt="site pro" src={ProImg1} />,
      <img alt="site pro" src={ProImg2} />
    ],
    scope: [
      'Marketing/landing page', 
      'Editorial pages', 
      'News/Events pages',
      'Kitbag section',
      'Custom gallery component' 
    ]
  };

  /**
   * Challenge Data
   */
  const challengeData = {
    challenge: 
      'To deliver an innovative and prospect pulling website that strengthens the company’s footprint on the internet space. As an industry giant, we needed to get an accurate picture of its portfolio and adopt a better and technical method of displaying its product catalogue.',
    solution: 
      'To meet the requirements in the scope of the project, we used a hybrid approach. We worked on getting the marketing/landing page right to properly maximize visibility and engagement rate. Additionally, we created an amazing custom product gallery that depicts the positive power of imaging technology.',
    solutionPoint: false,
    outcome: 
      'The marketing website was well received by our client. Being passionate customers of Canon ourselves, we are incredibly honoured to have been a part of such project. We’re excited about the possibilities of the new website and results it can attract.',
    outcomePoint: false,
    image: <img alt="pro site" src={ProImg3} />
  };

  /**
   * Testemonial data
   */
  // const testimonialData = {
  //   quote: 
  //     'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
  //   name: 'Fred',
  //   position: 'full-stack'
  // };

  /**
   * See more footer
   */
  const seeMoreData = {
    links: ['cps','jll','usimoney']
  };

  return (
    <Layout>
      <SEO 
        title="Clients" 
        keywords={
          [`digital`, `agency`, `technology`, `Radium`, `pro`, `Client`]
        } 
      />
      <Banner {...innerBannerData} />
      <Company {...descriptionData} />
      <Brief {...briefData} />
      <Challenge {...challengeData}/>
      <SeeMore {...seeMoreData} />
    </Layout>
  )
};
